<template>
    <div class="card">
        <div class="table-responsive">
            <table class="table nowrap">
                <thead>
                    <tr>
                        <th>Created By</th>
                        <th class="text-right">Amount</th>
                        <th class="text-right">VAT Amount</th>
                        <th class="text-right">Total Sales Amount</th>
                        <th class="text-right">Settlement Dis</th>
                        <th class="text-right">Net Bill Amount</th>
                        <th class="text-right">Paid Amount</th>
                        <th class="text-right">Return Amount</th>
                        <th class="text-right">Net Collection</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(child, childIndex) in props.users" :key="childIndex">
                        <td>{{ child.name }}</td>
                        <td class="text-right">{{ commaFormat(child.total_subtotal_amount) }}</td>
                        <td class="text-right">{{ commaFormat(child.total_vat_amount) }}</td>
                        <td class="text-right">{{ commaFormat(child.total_subtotal_amount + child.total_vat_amount) }}</td>
                        <td class="text-right">{{ commaFormat(child.total_discount) }}</td>
                        <td class="text-right">{{ commaFormat(child.total_subtotal_amount + child.total_vat_amount - child.total_discount) }}</td>
                        <td class="text-right">{{ commaFormat(child.total_paid_amount) }}</td>
                        <td class="text-right">{{ commaFormat(child.total_sale_return_paid_amount) }}</td>
                        <td class="text-right">{{ commaFormat(child.total_paid_amount-child.total_sale_return_paid_amount) }}</td>
                    </tr>
                    <tr>
                      <th class="text-right">Total ({{ props.users.length }})</th>
                      <th class="text-right">{{ commaFormat(getTotalAmount('total_subtotal_amount')) }}</th>
                      <th class="text-right">{{ commaFormat(getTotalAmount('total_vat_amount')) }}</th>
                      <th class="text-right">{{ commaFormat(getTotalAmount('total_subtotal_amount') + getTotalAmount('total_vat_amount')) }}</th>
                      <th class="text-right">{{ commaFormat(getTotalAmount('total_discount')) }}</th>
                      <th class="text-right">{{ commaFormat(getTotalAmount('total_subtotal_amount') + getTotalAmount('total_vat_amount') - getTotalAmount('total_discount')) }}</th>
                      <th class="text-right">{{ commaFormat(getTotalAmount('total_paid_amount')) }}</th>
                      <th class="text-right">{{ commaFormat(getTotalAmount('total_sale_return_paid_amount')) }}</th>
                      <th class="text-right">{{ commaFormat(getTotalAmount('total_paid_amount')-getTotalAmount('total_sale_return_paid_amount')) }}</th>
                    </tr>
                </tbody>
            </table>
          <p v-if="!props.users.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script setup>
import figureFormatter from "@/services/utils/figureFormatter";

const props = defineProps({
    users: Array
})

const { commaFormat } = figureFormatter();

const getTotalAmount = (column) => {
  return props.users.reduce((total, amount) => total + amount[column], 0);
}
</script>

<style scoped>
    .nowrap{
        white-space: nowrap;
    }
</style>
